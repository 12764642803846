import { useQuery, useQueryClient } from '@tanstack/vue-query'

export const useApiAffectedFields = (divisionId: string) => {
  return useQuery<Record<string, string[]>>({
    queryKey: [divisionId, 'affectedFields'],
    queryFn: () =>
      fetchAPI<Record<string, string[]>>(
        `/companies/${divisionId}/leaseRegister/affectedFields`,
      ),
  })
}

export const useApiPrefetchAffectedFields = async (divisionId: string) => {
  const queryClient = useQueryClient()

  await queryClient.prefetchQuery({
    queryKey: [divisionId, 'affectedFields'],
    queryFn: () =>
      fetchAPI<Record<string, string[]>>(
        `/companies/${divisionId}/leaseRegister/affectedFields`,
      ),
  })
}
